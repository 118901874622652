<template>
<div class="row">
   <div id="cart" class="cart-card-block show p-0 col-12">
      <div class="row align-item-center">
         <div class="col-lg-8">
            <div class="iq-card">
               <div class="iq-card-header d-flex justify-content-between iq-border-bottom mb-0">
                  <div class="iq-header-title">
                     <h4 class="card-title">Shopping Cart</h4>
                  </div>
               </div>
               <div class="iq-card-body">
                  <div class="checkout-product">
                     <div class="row align-items-center">
                        <div class="col-sm-2">
                           <span class="checkout-product-img">
                           <a href="javascript:void();"><img class="img-fluid rounded" src="@/assets/images/store/01.jpg" alt=""></a>
                           </span>
                        </div>
                        <div class="col-sm-4">
                           <div class="checkout-product-details">
                              <h5>The Raze night book</h5>
                              <p class="text-success">In stock</p>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div class="row">
                              <div class="col-sm-10">
                                 <div class="row align-items-center mt-2">
                                    <div class="col-sm-7 col-md-6">
                                       <button type="button" class="fa fa-minus qty-btn" id="btn-minus"></button>
                                       <input type="text" id="quantity" value="0">
                                       <button type="button" class="fa fa-plus qty-btn" id="btn-plus"></button>
                                    </div>
                                    <div class="col-sm-5 col-md-6">
                                       <span class="product-price">$15</span>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-2">
                                 <a href="javascript:void();" class="text-dark font-size-18"><i class="ri-delete-bin-7-fill"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="iq-card">
               <div class="iq-card-body">
                  <div class="checkout-product">
                     <div class="row align-items-center">
                        <div class="col-sm-2">
                           <span class="checkout-product-img">
                           <a href="javascript:void();"><img class="img-fluid rounded" src="@/assets/images/store/02.jpg" alt=""></a>
                           </span>
                        </div>
                        <div class="col-sm-4">
                           <div class="checkout-product-details">
                              <h5>Harsh Reality book</h5>
                              <p class="text-success">In stock</p>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div class="row">
                              <div class="col-sm-10">
                                 <div class="row align-items-center mt-2">
                                    <div class="col-sm-7 col-md-6">
                                       <button type="button" class="fa fa-minus qty-btn" id="btn-minus"></button>
                                       <input type="text" id="quantity" value="0">
                                       <button type="button" class="fa fa-plus qty-btn" id="btn-plus"></button>
                                    </div>
                                    <div class="col-sm-5 col-md-6">
                                       <span class="product-price">$25</span>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-2">
                                 <a href="javascript:void();" class="text-dark font-size-18"><i class="ri-delete-bin-7-fill"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="iq-card">
               <div class="iq-card-body">
                  <div class="checkout-product">
                     <div class="row align-items-center">
                        <div class="col-sm-2">
                           <span class="checkout-product-img">
                           <a href="javascript:void();"><img class="img-fluid rounded" src="@/assets/images/store/03.jpg" alt=""></a>
                           </span>
                        </div>
                        <div class="col-sm-4">
                           <div class="checkout-product-details">
                              <h5>The House in the Fog</h5>
                              <p class="text-success">In stock</p>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div class="row">
                              <div class="col-sm-10">
                                 <div class="row align-items-center mt-2">
                                    <div class="col-sm-7 col-md-6">
                                       <button type="button" class="fa fa-minus qty-btn" id="btn-minus"></button>
                                       <input type="text" id="quantity" value="0">
                                       <button type="button" class="fa fa-plus qty-btn" id="btn-plus"></button>
                                    </div>
                                    <div class="col-sm-5 col-md-6">
                                       <span class="product-price">$18</span>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-2">
                                 <a href="javascript:void();" class="text-dark font-size-18"><i class="ri-delete-bin-7-fill"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="iq-card">
               <div class="iq-card-body">
                  <div class="checkout-product">
                     <div class="row align-items-center">
                        <div class="col-sm-2">
                           <span class="checkout-product-img">
                           <a href="javascript:void();"><img class="img-fluid rounded" src="@/assets/images/store/04.jpg" alt=""></a>
                           </span>
                        </div>
                        <div class="col-sm-4">
                           <div class="checkout-product-details">
                              <h5>The badges</h5>
                              <p class="text-success">In stock</p>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div class="row">
                              <div class="col-sm-10">
                                 <div class="row align-items-center mt-2">
                                    <div class="col-sm-7 col-md-6">
                                       <button type="button" class="fa fa-minus qty-btn" id="btn-minus"></button>
                                       <input type="text" id="quantity" value="0">
                                       <button type="button" class="fa fa-plus qty-btn" id="btn-plus"></button>
                                    </div>
                                    <div class="col-sm-5 col-md-6">
                                       <span class="product-price">$28</span>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-2">
                                 <a href="javascript:void();" class="text-dark font-size-18"><i class="ri-delete-bin-7-fill"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="iq-card">
               <div class="iq-card-body">
                  <p><b>Order Details</b></p>
                  <div class="d-flex justify-content-between mb-2">
                     <span>cart(4)</span>
                     <span>$86</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                     <span>Bag Discount</span>
                     <span class="text-success">-10$</span>
                  </div>
                  <div class="d-flex justify-content-between mb-4">
                     <span>Delivery Charges</span>
                     <span class="text-success">Free</span>
                  </div>
                  <hr>
                  <div class="d-flex justify-content-between mb-4">
                     <span class="text-dark"><strong>Total</strong></span>
                     <span class="text-dark"><strong>$76</strong></span>
                  </div>
                  <router-link :to="{name:'store.store-checkoutaddress'}" id="place-order" class="btn btn-primary text-white d-block mt-3 next">Place order</router-link>
               </div>
            </div>
            <div class="iq-card ">
               <div class="card-body iq-card-body p-0 iq-checkout-policy">
                  <ul class="p-0 m-0">
                     <li class="d-flex align-items-center">
                        <div class="iq-checkout-icon">
                           <i class="ri-checkbox-line"></i>
                        </div>
                        <h6>Security policy (Safe and Secure Payment.)</h6>
                     </li>
                     <li class="d-flex align-items-center">
                        <div class="iq-checkout-icon">
                           <i class="ri-truck-line"></i>
                        </div>
                        <h6>Delivery policy (Home Delivery.)</h6>
                     </li>
                     <li class="d-flex align-items-center">
                        <div class="iq-checkout-icon">
                           <i class="ri-arrow-go-back-line"></i>
                        </div>
                        <h6>Return policy (Easy Retyrn.)</h6>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
export default {
  name: 'StoreCheckout-cart'
}
</script>
